import React from "react"
import ArrowZ from "../../../common/ArrowZ"
import Img from "../../../common/Img"

let insideCommon = [
  {
    link: "https://note.com/crispsaladworks/n/n8c63d682d59d",
    src: "/asset/img/careers/inside/220829.jpg",
    src2x: "/asset/img/careers/inside/220829@2x.jpg",
    srcWebp: "/asset/img/careers/inside/220829.jpg.webp",
    srcWebp2x: "/asset/img/careers/inside/220829@2x.jpg.webp",
    alt: "“フレキシブルな環境で、自分次第で着実にステップアップできる”CRISPパートナーインタビュー 〜しんじさん編〜",
    ttl: "“フレキシブルな環境で、自分次第で着実にステップアップできる”CRISPパートナーインタビュー 〜しんじさん編〜",
  },
  {
    link: "https://note.com/crispsaladworks/n/nbb53c1e2d2ae",
    src: "/asset/img/careers/inside/220819.jpg",
    src2x: "/asset/img/careers/inside/220819@2x.jpg",
    srcWebp: "/asset/img/careers/inside/220819.jpg.webp",
    srcWebp2x: "/asset/img/careers/inside/220819@2x.jpg.webp",
    alt: "【CRISP NIGHT Vol.1】カンペなし！CRISPで“働く”をのぞけるトークセッションを開催",
    ttl: "【CRISP NIGHT Vol.1】カンペなし！CRISPで“働く”をのぞけるトークセッションを開催",
  },
  {
    link: "https://note.com/crispsaladworks/n/n32480faca59c",
    src: "/asset/img/careers/inside/220819.jpg",
    src2x: "/asset/img/careers/inside/220819@2x.jpg",
    srcWebp: "/asset/img/careers/inside/220819.jpg.webp",
    srcWebp2x: "/asset/img/careers/inside/220819@2x.jpg.webp",
    alt: "CRISPの「働く」を知りたくない？トークイベント「CRISP NIGHT」 開催最新情報",
    ttl: "CRISPの「働く」を知りたくない？トークイベント「CRISP NIGHT」 開催最新情報",
  },
  {
    link: "https://note.com/crispsaladworks/n/ne28e71ec74ec",
    src: "/asset/img/careers/inside/220812.jpg",
    src2x: "/asset/img/careers/inside/220812@2x.jpg",
    srcWebp: "/asset/img/careers/inside/220812.jpg.webp",
    srcWebp2x: "/asset/img/careers/inside/220812@2x.jpg.webp",
    alt: "“「自分らしさ」が、「CRISPらしさ」をつくっていく”CRISPパートナーインタビュー 〜アエルさん編〜",
    ttl: "“「自分らしさ」が、「CRISPらしさ」をつくっていく”CRISPパートナーインタビュー 〜アエルさん編〜",
  },
  {
    link: "https://note.com/crispsaladworks/n/n85777c25f8ab",
    src: "/asset/img/careers/inside/220729.jpg",
    src2x: "/asset/img/careers/inside/220729@2x.jpg",
    srcWebp: "/asset/img/careers/inside/220729.jpg.webp",
    srcWebp2x: "/asset/img/careers/inside/220729@2x.jpg.webp",
    alt: "【CRISP TGIF】やりがい、働くならどんな人が向いている？ 突撃レポート＆インタビュー",
    ttl: "【CRISP TGIF】やりがい、働くならどんな人が向いている？ 突撃レポート＆インタビュー",
  },
  {
    link: "https://note.com/crispsaladworks/n/nc91d2f5973d8",
    src: "/asset/img/careers/inside/220727.jpg",
    src2x: "/asset/img/careers/inside/220727@2x.jpg",
    srcWebp: "/asset/img/careers/inside/220727.jpg.webp",
    srcWebp2x: "/asset/img/careers/inside/220727@2x.jpg.webp",
    alt: "“パートナーが幸せに働ける環境に”CRISPパートナーインタビュー 〜なおやさん編〜",
    ttl: "“パートナーが幸せに働ける環境に”CRISPパートナーインタビュー 〜なおやさん編〜",
  },
]

const Inside = () => {
  return (
    <section className="p-careers__common-inside">
      <div className="p-careers__common-inside__wrapper">
        <div className="p-careers__common-inside__header">
          <h2 className="c-ttl__en p-careers__common-inside__header-ttl">
            THE CRISP INSIDE
          </h2>
        </div>
        <div className="p-careers__common-inside__body">
          <ul className="p-careers__common-inside__body-list">
            {insideCommon.map(data => {
              return (
                <li className="p-careers__common-inside__body-item">
                  <a
                    className="js-hover p-careers__common-inside__body-item-link"
                    data-hover-in-time="600"
                    href={data.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="js-scroll__dark__sp c-link__img p-careers__common-inside__body-item-img">
                      <Img
                        src={data.src}
                        src2x={data.src2x}
                        // webpSrc={data.srcWebp}
                        // webpSrc2x={data.srcWebp2x}
                        alt={data.alt}
                        imgClassName="p-careers__common-inside__body-item-img-item"
                      />
                    </div>
                    <div className="p-careers__common-inside__body-item-content">
                      <h3 className="p-careers__common-inside__body-item-content-ttl">
                        {data.ttl}
                      </h3>
                    </div>
                  </a>
                </li>
              )
            })}
          </ul>
          <a
            className="js-hover p-careers__common-inside__body-link"
            data-hover-in-time="600"
            data-hover-out-time="650"
            href="https://note.com/crispsaladworks/m/m2a8de76fed4b"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="c-link__main p-careers__common-inside__body-link-wrapper">
              <span className="p-careers__common-inside__body-link-span">
                <span className="c-link__main-inner">THE CRISP INSIDE</span>
                <ArrowZ />
              </span>
            </div>
          </a>
        </div>
      </div>
    </section>
  )
}

export default Inside
