import React from "react"
import ArrowYUp from "./ArrowYUp"

const BtnMain = ({ location, title, children }) => {
  return (
    <button
      className="js-scroll__dark js-hover c-btn__main p-careers__btn"
      data-updown-trigger="careers"
      data-hover-in-time="600"
      data-hover-out-time="650"
    >
      <span className="c-btn__main__cover c-btn__main__cover__y">
        <span className="c-btn__main__wrapper">
          <span className="c-btn__main__txt">募集職種を見る</span>
          <ArrowYUp />
        </span>
      </span>
    </button>
  )
}

export default BtnMain
