import React from "react"

const ArrowYUp = ({ location, title, children }) => {
  return (
    <span className="c-icon__arrow">
    <span className="c-icon__arrow__origin c-icon__arrow__origin__y__up">
        <svg className="c-icon__arrow__def"
             width="16"
             height="17"
             viewBox="0 0 16 17"
             fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path d="M8.00098 15.0469L8.00098 1.04688"
                  stroke="#EAE8E3"
                  strokeWidth="2"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round" />
            <path d="M14.047 7.09277L8.00093 1.04666L1.95312 7.09277"
                  stroke="#EAE8E3"
                  strokeWidth="2"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round" />
        </svg>
    </span>
    <span className="c-icon__arrow__dammy c-icon__arrow__dammy__y__up">
        <svg className="c-icon__arrow__def"
             width="16"
             height="17"
             viewBox="0 0 16 17"
             fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path d="M8.00098 15.0469L8.00098 1.04688"
                  stroke="#EAE8E3"
                  strokeWidth="2"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round" />
            <path d="M14.047 7.09277L8.00093 1.04666L1.95312 7.09277"
                  stroke="#EAE8E3"
                  strokeWidth="2"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round" />
        </svg>
    </span>
</span>
  )
}

export default ArrowYUp