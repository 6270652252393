import React from "react"
import Img from "../../../common/Img"

const Saas = () => {
  let saasDetailsBusiness = [
    {
      src: "/asset/img/careers/logos/logo_slack.png",
      alt: "slack",
    },
    {
      src: "/asset/img/careers/logos/logo_google_meet.png",
      alt: "Google Meet",
    },
    {
      src: "/asset/img/careers/logos/logo_zapier.png",
      alt: "zapier",
    },
    {
      src: "/asset/img/careers/logos/logo_cloudsign.png",
      alt: "cloudsign",
    },
    {
      src: "/asset/img/careers/logos/logo_atena.png",
      alt: "atena",
    },
    //
    {
      src: "/asset/img/careers/logos/logo_google_workspace.png",
      alt: "Google Workspace",
    },
    {
      src: "/asset/img/careers/logos/logo_trello.png",
      alt: "trello",
    },
    {
      src: "/asset/img/careers/logos/logo_zendesk.png",
      alt: "zendesk",
    },
    {
      src: "/asset/img/careers/logos/logo_google_data_portal.png",
      alt: "Google datapotal",
    },
    {
      src: "/asset/img/careers/logos/logo_mailchimp.png",
      alt: "mailchimp",
    },
    {
      src: "/asset/img/careers/logos/logo_kuroten.png",
      alt: "kuroten",
    },
  ]

  let saasDetailsDevelopment = [
    {
      src: "/asset/img/careers/logos/logo_google_big_query.png",
      alt: "Google Big Query",
    },
    {
      src: "/asset/img/careers/logos/logo_google_cloud.png",
      alt: "Google Cloud",
    },
    {
      src: "/asset/img/careers/logos/logo_twilio.png",
      alt: "twilio",
    },
    {
      src: "/asset/img/careers/logos/logo_figma.png",
      alt: "Figma",
    },
    {
      src: "/asset/img/careers/logos/logo_square.png",
      alt: "Square",
    },
    {
      src: "/asset/img/careers/logos/logo_stripe.png",
      alt: "stripe",
    },
    {
      src: "/asset/img/careers/logos/logo_github.png",
      alt: "Github",
    },
    {
      src: "/asset/img/careers/logos/logo_github_actions.png",
      alt: "Github Actions",
    },
    {
      src: "/asset/img/careers/logos/logo_sentry.png",
      alt: "Sentry",
    },
  ]

  return (
    <section className="p-careers__common-saas p-careers__farm-saas">
      <div className="p-careers__common-saas__wrapper">
        <div className="p-contents p-contents__saas">
          <div className="p-contents__wrapper">
            <h2 className="c-ttl__en p-contents__en">SaaS TOOLS</h2>
            <div className="p-contents__block">
              <h3 className="c-ttl__main p-contents__ttl">
                SaaSを活用し
                <br className="u-none__sp" />
                プロダクトの開発をより効率的に
              </h3>
              <p className="c-txt__main p-contents__txt">
                開発をスピーディに進めるために必要なSaaSは、自由に導入することが可能です。積極的に開発環境をアップデートしていくことで、事業全体をドライブさせていきます。
              </p>
            </div>
          </div>
        </div>
        <div className="p-careers__common-saas__details-cover">
          <div className="swiper-container p-careers__common-saas__details p-careers__common-saas__details__2">
            <ul className="swiper-wrapper p-careers__common-saas__details-list">
              {saasDetailsBusiness.map(data => {
                return (
                  <li
                    className="swiper-slide p-careers__common-saas__details-item"
                    key={data.src}
                  >
                    <div className="p-careers__common-saas__details-item-img">
                      <Img src={data.src} src2x={data.src} alt={data.alt} />
                    </div>
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
        <div className="p-careers__common-saas__details-cover">
          <div className="swiper-container p-careers__common-saas__details p-careers__common-saas__details__1">
            <ul className="swiper-wrapper p-careers__common-saas__details-list">
              {saasDetailsDevelopment.map(data => {
                return (
                  <li
                    className="swiper-slide p-careers__common-saas__details-item"
                    key={data.src}
                  >
                    <div className="p-careers__common-saas__details-item-img">
                      <Img src={data.src} src2x={data.src} alt={data.alt} />
                    </div>
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Saas
