import React from "react"

const Modal = () => {
  return (
    <div className="p-careers-modal">
      <div className="p-careers-modal__wrapper">
        <span className="p-careers-modal__bg"></span>
        <iframe
          className="p-careers-modal__item"
          src="https://player.vimeo.com/video/580228423"
          allow="autoplay"
        ></iframe>
      </div>
    </div>
  )
}

export default Modal
