import React from "react"
import Img from "../../../common/Img"

const Initiative = () => {
  let initiativeDetails = [
    {
      src: "/asset/img/careers/restaurant/initiative_1.jpg",
      src2x: "/asset/img/careers/restaurant/initiative_1@2x.jpg",
      srcWebp: "/asset/img/careers/restaurant/initiative_1.jpg",
      srcWebp2x: "/asset/img/careers/restaurant/initiative_1@2x.jpg",
      alt: "店内で接客するスタッフ",
      ttl: "お客様の注文データに基づいた接客",
      txt: "CRISP APPやKIOSKの注文から、来店頻度やその人の好みなどをデータ化。パートナーの誰もが、お客様一人ひとりに最適な接客ができるようにします。",
    },
    {
      src: "/asset/img/careers/restaurant/initiative_2.jpg",
      src2x: "/asset/img/careers/restaurant/initiative_2@2x.jpg",
      srcWebp: "/asset/img/careers/restaurant/initiative_2.jpg",
      srcWebp2x: "/asset/img/careers/restaurant/initiative_2@2x.jpg",
      alt: "iPhoneでslackを操作",
      ttl: "SaaSによる業務とコミュニケーションの効率化",
      txt: "自社のSaaSで管理業務をシステム化しオンライン上で完結。Slackの活用で社内コミュニケーションも円滑に。現場のパートナーが、接客に集中できる時間をより多くします。",
    },
    {
      src: "/asset/img/careers/restaurant/initiative_3.jpg",
      src2x: "/asset/img/careers/restaurant/initiative_3@2x.jpg",
      srcWebp: "/asset/img/careers/restaurant/initiative_3.jpg",
      srcWebp2x: "/asset/img/careers/restaurant/initiative_3@2x.jpg",
      alt: "オンライン接客するロボット",
      ttl: "未来を見据え、テクノロジーの研究や開発にも積極的にチャレンジ",
      txt: "サラダ制作ロボットを研究・開発するなど、未来に向けて積極的にテクノロジーを取り入れることで、常にお客様があっと驚くような体験を提供します。",
    },
  ]

  return (
    <section className="p-careers__common-initiative">
      <div className="p-careers__common-initiative__wrapper">
        <div className="p-contents p-contents__initiative">
          <div className="p-contents__wrapper">
            <h2 className="c-ttl__en p-contents__en">OUR INITIATIVE</h2>
            <div className="p-contents__block">
              <h3 className="c-ttl__main p-contents__ttl">
                期待を超える顧客体験を届けるために
              </h3>
              <p className="c-txt__main p-contents__txt">
                私たちは、お客様とサラダをつなぐ顧客体験をより良いものにしていくために、CRISPのすべてのパートナーと共に様々な挑戦に取り組んでいます。
              </p>
            </div>
          </div>
        </div>
        <div className="p-careers__common-initiative__details">
          <ul className="p-careers__common-initiative__details-list">
            {initiativeDetails.map(data => {
              return (
                <li className="p-careers__common-initiative__details-item">
                  <div className="p-careers__common-initiative__details-item-wrapper">
                    <div className="js-scroll__dark p-careers__common-initiative__details-item-img">
                      <Img
                        src={data.src}
                        src2x={data.src2x}
                        webpSrc={data.srcWebp}
                        webpSrc2x={data.srcWebp2x}
                        alt={data.alt}
                      />
                    </div>
                    <div className="p-careers__common-initiative__details-item-content">
                      <h4
                        className="p-careers__common-initiative__details-item-content-ttl"
                        dangerouslySetInnerHTML={{ __html: data.ttl }}
                      ></h4>
                      <p
                        className="c-txt__min p-careers__common-initiative__details-item-content-txt"
                        dangerouslySetInnerHTML={{ __html: data.txt }}
                      ></p>
                    </div>
                  </div>
                </li>
              )
            })}
          </ul>
        </div>
      </div>
    </section>
  )
}

export default Initiative
